import React from "react";
import { Layout, Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import "./pages.css";

const Disclaimer = (props) => {
  return (
    <Layout {...props}>
      <Seo title="3air Disclaimers" />
      <Divider />
      <Stack effectProps={{ effect: "fadeInDown" }}>
        <PageTitle header="Token Disclaimer" />
      </Stack>
      <Stack>
        <Main>
          <p>
            3air Tokens are utility tokens designed to be used on the 3air
            Platform developed by 3air tech Ltd., and parent companies,
            subsidiaries, affiliates, agents, representatives, predecessors,
            advisors, and the successors and assigns (the “3air Parties’). 3air
            Tokens have an expected future usage solely as a utility token
            within the 3air Platform.
          </p>
          <p>
            3air Tokens are not a security of any kind, and they do not
            represent any right to vote, manage, or share in the profits or
            proceeds of any entity. 3air Tokens do not represent ownership of
            any physical asset. The 3air Parties do not guarantee or suggest any
            economic return from receiving 3air Tokens, and you should not use
            3air Tokens for any speculative purposes. Purchasing 3air Tokens
            should be without expectation of any profit, dividend, capital gain,
            financial yield or any other return, payment or income of any kind.
          </p>
          <p>
            It should not be understood, deemed, interpreted, or construed,
            under the laws of any applicable jurisdiction, to equate 3air Tokens
            to any kind of:
          </p>
          <ol>
            <li>
              money, legal tender, or currency, whether fiat or otherwise;
            </li>

            <li>
              equity or proprietary interest in any entity, scheme, or venture;
            </li>

            <li>
              investment in any entity, common enterprise, scheme, or venture,
              taking any form, whether equity, debt, or as a commodity (or any
              combination thereof, whether to be delivered in the future or
              otherwise);
            </li>

            <li>
              instrument, entity, scheme, or venture that participates or
              receives any dividend, payment, profit, income, distribution or
              other economic returns;
            </li>

            <li>
              security, futures contract, derivative, deposit, negotiable
              instrument (including commercial paper), investment contract or
              collective investment scheme between the holder and any other
              person or entity; or
            </li>

            <li>
              asset or commodity (whether to be delivered in the future or
              otherwise), including any asset or commodity that any person or
              entity is obliged to repurchase or redeem.
            </li>
          </ol>

          <p>
            3air Tokens are not being offered to (a) any U.S. Person; (b) or any
            citizen or resident or legal entity of (i) the United States of
            America, the People’s Republic of China, Afghanistan, Belarus,
            British Virgin Islands, Central African Republic, Congo, Democratic
            Republic of the Congo, Republic of the Cote D'Ivoire, Crimea region
            of Ukraine, Cuba, El Salvador, Eswatini, Gambia, Iran, Iraq,
            Liberia, Libya, Malawi, Mali, Moldova, Myanmar, Niger, North Korea,
            Palestinian Territory, South Sudan, Sudan, Syria, Venezuela,, Yemen,
            Zambia, Zimbabwe; (ii) any state, country or other jurisdiction that
            is sanctioned and/or embargoed by the United States of America, the
            European Union and/or Switzerland; (iii) a jurisdiction where it
            would be illegal according to local law or regulation for you to
            purchase Tokens; or (iv) where the sale of Tokens is prohibited or
            contrary to local law or regulation, or could subject 3air to any
            local registration, regulatory or licensing requirements.
          </p>

          <h2>Forward Looking Statements Disclaimer</h2>
          <p>
            Certain information contained in this document constitutes "forward
            looking statements", which can be identified by the use of
            forward-looking terminology such as "may", "will", "should",
            "expect", "anticipate", "project", "estimate", "intend", or
            "believe" or the negatives thereof or other variations thereon or
            comparable terminology. Due to various risks and uncertainties,
            actual events or results or the actual performance of 3air Tokens
            and/or the 3air Platform may differ materially from those reflected
            or contemplated in such forward looking statements.
          </p>
        </Main>
      </Stack>
    </Layout>
  );
};

export default Disclaimer;
